<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="activity-page">
    <b-card class="" title="Monitor Activities">
      <b-card-text>This page shows allows you to see all different types of activities across users, owners, groups, networks and roles.</b-card-text>
      <b-card-text>You can view a user from any of the activities.</b-card-text>
    </b-card>
    <b-card no-body class="mb-0">
      <div class="row px-2 pt-1 justify-content-end">
        <section class="col-3">
          <label>Model</label>
          <b-form-select @change="getMoreActivity(1)" v-model="filters.model" class="mb-2">
            <b-form-select-option value="">Select a model</b-form-select-option>
            <b-form-select-option v-for="option in modelOptions" :key="option" :value="option">{{ option | capEachWord }}</b-form-select-option>
          </b-form-select>
        </section>

        <section class="col-3">
          <label>User</label>
          <model-list-select :list="users"
                             @input="startNewSearch()"
                             :class="{'disabled': users.length === 0}"
                             v-model="filters.user_uid"
                             option-value="uid"
                             option-text="name"
                             placeholder="select user"/>
        </section>

        <div class="ui-spacer"></div>

        <section class="col-3 d-flex justify-content-end align-items-center">
          <b-button @click="clearFilters()">Clear filters</b-button>
        </section>
      </div>
      <section>
        <b-table
          class="data-table"
          hover
          :items="activity"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(created_at)="data">
            {{data.item.created_at | formatDateTime}}
          </template>
          <template #cell(actions)="data">
            <router-link v-if="!data.item.user.service_account" :to="{ name: 'user-view', params: { uid: data.item.user.uid } }" class="font-weight-bold d-block text-nowrap">
              View user
            </router-link>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreActivity" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <p v-if="total_count === 0" class="col-6 ml-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
  import 'vue-search-select/dist/VueSearchSelect.css';
  import { ModelListSelect } from 'vue-search-select'
  import ActivityService from '../../services/ActivityService';
  import UserService from '../../services/UserService';
  import OwnersService from '../../services/OwnersService';

  export default {
    name: 'Activities',
    components: {
      ModelListSelect,
    },
    props: {},
    data() {
      return {
        modelOptions: [
          'user',
          'role',
          'group',
          'network',
          'owner',
        ],
        headers: [
          {
            key: 'created_at',
            label: 'Date',
          },
          {
            key: 'message',
            label: 'Message',
          },
          {
            key: 'model_type',
            label: 'Model Type',
          },
          {
            key: 'model_name',
            label: 'Model Name',
          },
          {
            key: 'actions',
            label: '',
            class: 'text-right',
          },
        ],
        activity: [],
        users: [],
        owners: [],
        currentPage: 1,
        filters: {
          page: 0,
          page_size: 15,
          model: '',
          user_uid: '',
          owner_uid: '',
        },
        total_count: 0,
        timeout: null,
      };
    },
    mounted() {
      this.getActivity();
      this.getUsers();
      // this.getOwners();
    },
    methods: {
      startNewSearch() {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          this.getMoreActivity(1);
        }, 500);
      },
      getMoreActivity(val) {
        this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
        this.getActivity();
      },
      getActivity() {
        ActivityService.getActivity(this.filters).then(res => {
          this.activity = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get activity, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      getUsers() {
        UserService.getUsers({
          page: 0,
          page_size: 30,
        }).then(res => {
          this.users = res.data.data;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get users, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      getOwners() {
        OwnersService.getOwners({
          page: 0,
          page_size: 30,
        }).then(res => {
          this.owners = res.data.data;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get owners, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      clearFilters() {
        this.filters = {
          page: 0,
          page_size: 25,
          model: '',
          user_uid: '',
        }

        this.getActivity();
      },
    },
  };
</script>
